<template>
    <div id="loading" class="loading-wrap loader">
        <ul class="loading">
            <li><img  src="@/assets/images/loading.gif"></li>
            <li>Loading...</li>
        </ul>
    </div>
</template>
<script>
export default{
    name:'Loader'
}
</script>