<template>
    <footer class="mobile-footer-menu-hide" v-if="cricketFightRoute()">
        <nav class="mobile-footer-menu">
            <ul>
                <li>
                    <router-link to="/dashboard" class="ui-link" :class="{active : this.$route.name == 'dashboard'}">
                        <img  src="@/assets/images/trophy.webp" class="icon-sports">
                        {{ translatedLangData('sport','Sports')}}
                    </router-link>
                </li>
                <li>
                    <router-link to="/inplay" class="ui-link" :class="{active : this.$route.name == 'inplay'}">
                        <img  src="@/assets/images/timer.webp" class="icon-inplay">
                        {{ translatedLangData('inplay','In-Play ')}}
                    </router-link>
                </li>
                <li class="main-nav">
                    <router-link to="/" class="ui-link" :class="{active : this.$route.name == 'Home'}">
                        <img  src="@/assets/images/home.webp" class="icon-home">
                        {{ translatedLangData('home','Home')}}
                    </router-link>
                </li>
                <li>
                    <a @click="clickOnMultimarket()" href="Javascript:void(0);" class="ui-link" :class="{active : this.$route.name == 'multi-market'}">
                        <img  src="@/assets/images/pin-white-footer.svg" class="icon-pin">
                        {{ translatedLangData('multi-market','Multi Market')}}
                    </a>
                </li>
                <li>
                    <a @click="clickOnAccount()" class="ui-link" :class="{active : this.$route.name == 'mobile-account'}">
                        <img  src="@/assets/images/user.webp" class="icon-account">
                        {{ translatedLangData('account','Account')}}
                    </a>
                </li>
                <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop-one">
                </div>
            </ul>
        </nav>
    </footer>
    <template v-if="whatsappIconSHow()">
        <div class="whatsapp-footer-fixed-icn" v-if="siteSettings && siteSettings?.business_type == 2" data-bs-toggle="modal" data-bs-target="#whatsup-depositModal">
            <a href="javascript:void(0);" @click="getParentWhatsappNumber">
                <img  src="@/assets/images/whatsapp.png" class="whatsapp">
            </a>
        </div>
    </template>

    <div class="modal fade whatsup-modal-wrapper" id="whatsup-depositModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered information-modal-wrapper">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>Customer Support</h3>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="whatsappNumbers && whatsappNumbers.length > 0">
                        <div v-for="(data, index) in whatsappNumbers" :key="index">
                            <a class="whatsapp-now-modal-btn" :href="'https://wa.me/' + data.whatsapp_number" target="_blank">
                                <img  src="@/assets/images/whatsapp.png" class="whatsapp">
                                {{ data.category_name }}
                            </a>
                        </div>
                    </div>
                    <div v-else-if="siteSettings && (siteSettings.social_link?.whatsappno1 || siteSettings.social_link?.whatsappno2)">
                        <div v-for="(number, index) in [siteSettings.social_link?.whatsappno1, siteSettings.social_link?.whatsappno2]" :key="index" class="whatsapp-content-wrapper">
                            <a v-if="number" :key="number" :href="'https://wa.me/' + number" target="_blank" class="whatsapp-now-modal-btn" >
                                <img  src="@/assets/images/whatsapp.png" class="whatsapp">
                                {{ number }}
                            </a>
                        </div>
                    </div>
                    <div v-else>
                        <p>No WhatsApp numbers available.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'MobileFooter',
    inject:['translatedLangData'],
    data() {
        return {
            whatsappNumbers:[],
        }
    },
    computed: {
        ...mapGetters([
            'siteSettings',
        ])
    },
    methods: {
        clickOnAccount() {
            if (this.checkIsLogin()) {
                this.$router.push({name:'mobile-account'});
            }
            else {
                this.$refs.open_login_modal.click();
            }
        },
        clickOnMultimarket() {
            if (this.checkIsLogin()) {
                this.$router.push({name:'multi-market'});
            }
            else {
                this.$refs.open_login_modal.click();
            }
        },       
        checkIsLogin(){
            return this.$store.getters.isAuthenticated;
        },
        cricketFightRoute() {
			let routeName = this.$route.name;
			let restrictedRoutes = [
				// 'CricketFight',
				// 'MatchDetails',
				// 'MyContests',
				// 'MyContestBetDetails',
                // 'PlaceBet',
                'matka',
                'matka-details'
			]
			return !restrictedRoutes.includes(routeName);
		},
        whatsappIconSHow() {
            let routePath = this.$route.path;
            return routePath === '/';
        },
        async getParentWhatsappNumber() {
            if (this.checkIsLogin()) {
                this.loading = true;
                try {
                    const cpiExists = this.$store.getters.stateUser?.cpi;
                    const supportNumbers = this.siteSettings.support_numbers || [];
                    if (!cpiExists) {
                        return;
                    }
                    const now = new Date();
                    const totalMinutes = now.getHours() * 60 + now.getMinutes();

                    this.whatsappNumbers = supportNumbers
                        .filter(entry => entry.id === cpiExists)
                        .flatMap(entry => entry.categories.map(category => {
                            const whatsappNumbers = category.value.split(',');
                            const selectedNumberIndex = totalMinutes % whatsappNumbers.length;
                            return {
                                category_name: category.name,
                                whatsapp_number: whatsappNumbers[selectedNumberIndex]
                            };
                        }));
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.showErrorModalFunc(error[0]);
                }
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        }
    }
}
</script>