export const SITE_SETTINGS='api/site-settings';
export const LOGOUT_API='api/logout';
export const CHANGE_STAKE_VALUES='api/change-stake-values';
export const GET_SPORTS= 'v1/sports/management/getSport';
export const GET_FAVOURITE_LIST='api/favorite-list';
export const SEARCH='api/search';
export const WALLET_BALANCE='wallet';
export const AUTH_REFRESH='api/auth-refresh';
export const WALLET_LIMITS='api/wallet-limits';
export const GET_BONUS_LOGS= 'api/get-bonus-log';
export const SUBSCRIBE_NOTIFICATION= 'api/subscribe-notification';
export const NOTIFICATIONS_LIST= 'get/all/notification/history';