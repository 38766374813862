
import DashboardRoutes from "@/modules/dashboard";
import SportsRoutes from "@/modules/sports";
import { createRouter, createWebHistory } from "vue-router";
import RaceRoutes from "@/modules/race";
import ProfileRoutes from "@/modules/profile";
import BetsRoutes from "@/modules/bets";
import GamesRoutes from "@/modules/games";
import MultiMarketRoutes from "@/modules/multi-market";
import AccountRoutes from "@/modules/account";
import WalletRoutes from "@/modules/wallet";
import ReferEarnRoutes from "@/modules/refer-earn";
import BonusRoutes from "@/modules/bonus";
import MarketAnalysisRoutes from "@/modules/market-analysis";
import MatkaRoutes from "@/modules/matka";
import CricketFightRoutes from "@/modules/cricket-fight";
const Home = () => import("../views/Home.vue");
const Login = () => import("@/modules/authorization/components/Login.vue");
const SignUp = () => import("@/modules/authorization/components/SignUp.vue");
const ForgetPassword = () => import("@/modules/authorization/components/ForgetPassword.vue");


const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/login",
        name: "login",
        component: Login
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: SignUp
      },
      {
        path: "/forget-password",
        name: "forget-password",
        component: ForgetPassword
      },
    DashboardRoutes,
    SportsRoutes,
    RaceRoutes,
    ProfileRoutes,
    BetsRoutes,
    GamesRoutes,
    MultiMarketRoutes,
    AccountRoutes,
    WalletRoutes,
    BonusRoutes,
    MarketAnalysisRoutes,
    ReferEarnRoutes,
    MatkaRoutes,
    CricketFightRoutes
];

export default createRouter({
    history: createWebHistory(),
    routes
});
