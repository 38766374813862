<template>
    <div class="modal fade worli-Bets-Modal Betamount" id="clickBetValue" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <div class="thm-heading">
                            <div class="match-faq statement-match-faq">
                                <div class="match-head">
                                    <h5>{{ translatedLangData('click-bet-value', 'Click Bet Value') }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
                </div>
                <div class="modal-body">
                    <div class="stakeed-sec withdraw-sec bg-transparent border-0 singlestakeed-sec modal-stakeed-sec">
                        <div class="thm-gradient-but">
                            <ul v-if="inputvalue">
                                <li><input  @click="selectInputValue(0)" :class="{ 'border-input': inputvalue[0].isSelected }"
                                        :value="inputvalue[0].value" class="btn-thm m-auto" maxlength="4" @input="isNumber($event, 0)">
                                </li>
                                <li><input  @click="selectInputValue(1)" :class="{ 'border-input': inputvalue[1].isSelected }"
                                        :value="inputvalue[1].value" class="btn-thm m-auto" maxlength="4" @input="isNumber($event, 1)">
                                </li>
                                <li><input @click="selectInputValue(2)" :class="{ 'border-input': inputvalue[2].isSelected }"
                                        :value="inputvalue[2].value" class="btn-thm m-auto" maxlength="4"  @input="isNumber($event, 2)">
                                </li>
                                <li><input @click="selectInputValue(3)" :class="{ 'border-input': inputvalue[3].isSelected }"
                                        :value="inputvalue[3].value" class="btn-thm m-auto" maxlength="4"  @input="isNumber($event, 3)">
                                </li>
                            </ul>
                        </div>
                        
                        <div class="min-max-section">
                            <div class="min-max-con">
                                <h6>{{ translatedLangData('min', 'Min') }} : <span>100.00</span>
                                </h6>
                            </div>
                            <div class="min-max-icon">
                                <img src="@/assets/images/min-max.webp" alt="min-max">
                            </div>
                            <div class="min-max-con">
                                <h6>{{ translatedLangData('max', 'Max') }} : <span>5,000.00</span>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="header-but  info-footer edit-stake-btn">
                        <button type="button" class="thm-but thm-bor-but" data-bs-dismiss="modal" @click="closeModal">
                            {{ translatedLangData('cancel', 'Cancel') }}
                        </button>
                        <a @click="saveClickBetValue()" :class="!isAnySelected?'button-disabled':''" class="thm-but">
                            {{ translatedLangData('save', 'Save') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';
export default {
    name: "ClickBetModal",
    emits: ['close', 'success-modal', 'error-modal','close-modal'],
    inject:['translatedLangData'],
    data() {
        return {
            inputvalue: null,
            clickBetModal: null,
            selectedValue:false
        }
    },
    mounted() {
        if (this.getClickBetValue()) {
            this.inputvalue = JSON.parse(JSON.stringify([...this.getClickBetValue()]))
        }
        else {
            this.inputvalue = [
                {
                    value: 1000,
                    isSelected: true,
                },
                {
                    value: 2000,
                    isSelected: false,
                },
                {
                    value: 3000,
                    isSelected: false,
                },
                {
                    value: 4000,
                    isSelected: false,
                }
            ]
        }
        this.clickBetModal = new Modal('#clickBetValue');
    },
    computed:{
        isAnySelected() {
            return this.inputvalue?.some(obj => obj.isSelected);
        },
    },
    methods: {
        isNumber(evt, index) {
            let newValue = evt.target.value;

            if (newValue.length === 1 && newValue === '0') {
                this.inputvalue[index].value = '';
                evt.target.value = '';
            } else if (evt.data == '0' && newValue.startsWith('0')) {
                evt.target.value = Number(this.inputvalue[index].value);
            } else if (/^\d+$/.test(newValue)) {
                this.inputvalue[index].value = Number(newValue);
            } else {
                if (newValue !== '') {
                    evt.target.value = Number(this.inputvalue[index].value);
                } else {
                    this.inputvalue[index].value = '';
                }
            }
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue?[...this.$store.getters.clickBetValue]:null;
        },
        selectInputValue(index) { 
            this.selectedValue =  true;    
            let newinputvalue = JSON.parse(JSON.stringify(this.inputvalue)) 
            this.inputvalue =  newinputvalue
            for (let i = 0; i < this.inputvalue.length; i++) {
                this.inputvalue[i].isSelected = false;
            }
            this.inputvalue[index].isSelected = true;
        },
        saveClickBetValue() {
            if(this.inputvalue.some(obj => obj.isSelected)){
                let values = this.inputvalue.map(obj => obj.value);
                let uniqueValues = new Set(values);
                if (values.length !== uniqueValues.size) {
                    this.$emit('error-modal', 'Duplicate values are not allowed.');
                    return;
                }
            for (let i = 0; i < this.inputvalue.length; i++) {
                if(this.inputvalue[i].value < 100 || this.inputvalue[i].value > 5000) {
                    this.$emit('error-modal','Value should be in Between 100 to 5000.');
                    return;
                }
            }
            this.clickBetModal.hide();
            this.$store.dispatch('setClickBetValue', [...this.inputvalue]);
            this.selectedValue =  false;    
            this.$emit('success-modal','Click bet value updated successfully.');
            }
        },
        closeModal(){
            this.selectedValue =  false;   
            if(this.getClickBetValue()){
                this.inputvalue = JSON.parse(JSON.stringify([...this.getClickBetValue()]))
            }
            this.$emit('close-modal',this.$store.getters.clickBetActive.isSelected?this.$store.getters.clickBetActive.isSelected:this.$store.getters.clickBetActive)
        }
    }
};
</script>

<style scoped>
.border-input {
    border: 2px solid  !important;
}
.button-disabled{
    opacity: 0.4;
}   
</style>