<template>
    <div class="modal fade change-lang-modal" id="language_selection_pop_up" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>{{ translatedLangData('change-language','Change Your Language')}}</h3>
                    </div>
                    <img data-bs-dismiss="modal" class="gp-closeButton" @click="closeModal" src="@/assets/images/closeButton.webp" alt="">
                </div>
                <div class="modal-body">
                    <section class="sub-header-wrapper">
                        <div class="langauage-content">
                            <div class="search-lang-input">
                                <input type="search" placeholder="Search" v-model="searchTerm" class="form-control">
                                <button class="btn link-btn">
                                    <img src="@/assets/images/search-grey-icon.webp" alt="search">
                                </button>
                            </div>
                            <div class="custom-language-list">
                                <ul>
                                    <li v-for="item in filteredLanguages" :key="item.lang_code"
                                        :class="{ 'active': tempSelectedValue.lang_code == item.lang_code }">
                                        <div class="form-check" @click="selectLanguage(item)">
                                            <label class="form-check-label">
                                                {{ item.lang_name }}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            </div>
                            <div class="two-btns">
                                <ul class="cmn-ul-list">
                                    <li><button class="thm-but btn thm-bor-but" data-bs-dismiss="modal" @click="closeModal">
                                            {{ translatedLangData('close','Close')}}
                                        </button>
                                    </li>
                                    <li><button class="btn thm-but" @click="saveSelectedLanguage">
                                            {{ translatedLangData('update','Update')}}
                                        </button>
                                    </li>
                                </ul> 
                            </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import LanguageService from '@/shared/services/language-service.js'
export default {
    name: 'GamesPop1',
    inject: ['translatedLangData'],
    data() {
        return {
            selectedValue: {}, // The saved selected language
            tempSelectedValue: {}, // The temporary selected language in the modal
            searchTerm: ''
        }
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        languagesData() {
            return this.$store.getters.languagesData;
        },
        filteredLanguages() {
            if (this.searchTerm) {
                const searchTermLower = this.searchTerm.toLowerCase();
                return this.languagesData.filter(item => item.lang_name.toLowerCase().includes(searchTermLower));
            }
            return this.languagesData;
        }
    },
    watch: {
        selectedValue: {
            immediate: true,
            handler(newValue) {
                this.tempSelectedValue = { ...newValue };
            }
        }
    },
    mounted() {
        const selectedLangCode = localStorage.getItem('selectedLanguageCode');
        if (selectedLangCode) {
            this.selectedValue = this.languagesData?.find(item => item.lang_code == selectedLangCode);
        } else {
            this.selectedValue = this.languagesData?.find(item => item.lang_code == (this.siteSettings?.language_code || 'en'));
        }
        this.tempSelectedValue = { ...this.selectedValue };
    },
    methods: {
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        selectLanguage(item) {
            this.tempSelectedValue = item;
        },
        closeModal() {
            this.tempSelectedValue = { ...this.selectedValue }; // Reset to the saved selected value
        },
        async saveSelectedLanguage() {
            this.selectedValue = { ...this.tempSelectedValue }; // Save the temporary selected value
            localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
            localStorage.setItem('selectedLanguageCode', this.selectedValue.lang_code);
            if (this.selectedValue) {
                const aws_url_language = `${this.siteSettings.aws_url}/${this.selectedValue.lang_url}`;
                this.$parent.langData = await LanguageService.getLanguageTranslationKey(aws_url_language);
                localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
                this.showSuccessModalFunc('Language Updated Successfully');
                document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
            }
        }
    }
}
</script>