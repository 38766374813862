<template>
    <div class="modal-dialog onload-modal  modal-dialog-centered">
        <div class="modal-content">
            <div class="container">
                <div class="recent-bank-sec">
                    <div class="recent-bank">
                        <div class="bank-icon">
                            <img  src="@/assets/images/right-icon.webp" alt="">
                        </div>
                        <div class="bank-con">
                            <h6>{{ this.translatedLangData('success', 'Success') }}!</h6>
                            <span v-html="message"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SuccessModal",
    props: ['message'],
    inject:['translatedLangData'],
    methods: {
        // capitalizeFirstLetter(string) {
        //     if (!string) return '';
        //     return string?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        // }
    }
}
</script>