<template>
    <div class="login-account">
        <div class="modal fade register-page1" id="staticBackdrop-one" data-bs-backdrop="static" data-bs-keyboard="false"
            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <button ref="close_btn" @click="refreshData()" type="button" class="btn-close close-top" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div class="col-12 col-md-5">
                                    <div class="text-center">
                                        <img class="img-fluid logoregisterPage" :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="logo">
                                        <img src="@/assets/images/betfairlogo.webp" class="img-fluid m-top">
                                        <img src="@/assets/images/loginImg2.webp" class="img-fluid filterlogin m-top">
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <h2 class="regi-acc">{{ translatedLangData('login-account','Login Account')}}</h2>
                                    <form class="reg-form">
                                        <div class="form-login-tabs">
                                            <nav v-if="siteSettings?.business_type == 2">
                                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                    <button class="nav-link" :class="form.type == 1 ? 'active': ''" id="nav-Phone-tab" 
                                                        data-bs-toggle="tab" data-bs-target="#nav-Phone" type="button" role="tab"
                                                        aria-controls="nav-Phone" aria-selected="true" @click="clickOnType(1)">
                                                        {{ translatedLangData('phone','Phone')}}
                                                    </button>
                                                    <button class="nav-link" :class="form.type == 2 ? 'active': ''" id="nav-User-tab" 
                                                        data-bs-toggle="tab" data-bs-target="#nav-User" type="button" role="tab" 
                                                        aria-controls="nav-User" aria-selected="false" @click="clickOnType(2)">
                                                        {{ translatedLangData('user-id','User ID')}}
                                                    </button>
                                                </div>
                                            </nav>
                                            <div class="tab-content" id="nav-tabContent" v-if="siteSettings?.business_type == 2">
                                                <div class="tab-pane fade" :class="form.type == 1 ? 'show active': ''" id="nav-Phone" role="tabpanel" aria-labelledby="nav-Phone-tab">
                                                    <div class="get-mob" :disabled="otpSent" :class="{ 'get-mob': !otpSent, 'get-mob-dis': otpSent }">
                                                        <div class="col-auto">
                                                            <select v-model="form.code" class="form-select form-control" id="autoSizingSelect" :disabled="otpSent"
                                                                :class="{ 'enabled': !otpSent, 'disabled': otpSent }">
                                                                <option :value="allowedCountry.phonecode"
                                                                    v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                                    :key="countryIndex">+{{ allowedCountry.phonecode }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="inp-img">
                                                            <img src="@/assets/images/mob.webp" alt="">
                                                        </div>
                                                        <input v-model="form.mobileNo" type="tel" minlength="10" maxlength="10" class="form-control pl-0" 
                                                            :placeholder="translatedLangData('enter-phone-number', 'Enter Phone Number*')" @input="handleInput($event)">
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" :class="form.type == 2 ? 'show active': ''" id="nav-User" role="tabpanel" aria-labelledby="nav-User-tab">
                                                    <div class="get-mob">
                                                        <div class="inp-img">
                                                            <img src="@/assets/images/user-icon.webp" alt="">
                                                        </div>
                                                        <input type="text" class="form-control add" v-model="form.userId" 
                                                            :placeholder="translatedLangData('enter-user-id', 'Enter User ID*')" @input="handleInputUserID($event)">
                                                    </div>    
                                                </div>
                                            </div>
                                            <div class="tab-content" id="nav-tabContent" v-else>
                                                <div class="tab-pane fade show active" id="nav-User" role="tabpanel" aria-labelledby="nav-User-tab">
                                                    <div class="get-mob">
                                                        <div class="inp-img">
                                                            <img src="@/assets/images/user-icon.webp" alt="">
                                                        </div>
                                                        <input type="text" class="form-control add" v-model="form.userId" 
                                                        :placeholder="translatedLangData('enter-user-id', 'Enter User ID*')" @input="handleInputUserID($event)">
                                                    </div>    
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <p v-if="showValidationError && form.type == 1 && form.mobileNo == ''" class="wrong-message mt-1">
                                                {{ translatedLangData('please-enter-mobile-number','Please Enter Phone Number')}}.
                                            </p>
                                            <p v-if="showValidationError && form.type == 2 && form.userId == ''" class="wrong-message mt-1">
                                                {{ translatedLangData('please-enter-user-id','Please Enter User ID')}}.
                                            </p>
                                        </div>
                                        <div class="get-mob for eye-pass-visible">
                                            <input :type="passwordFieldType" v-model="form.password" :placeholder="translatedLangData('enter-pass', 'Enter Password*')"
                                                class="form-control" @input="filterSpaces">
                                            <div class="inp-img">
                                                <img src="@/assets/images/pass.webp" alt="">
                                            </div>
                                            <div class="eye-icn-sec" @click="togglePasswordVisibility()">
                                                <img v-if="passwordFieldType === 'password'" src="@/assets/images/eye-close.png">
                                                <img v-else src="@/assets/images/eye-open.webp">
                                            </div>
                                        </div>
                                        <div>
                                            <p v-if="showValidationError && form.password == ''" class="wrong-message mt-1">
                                                {{ translatedLangData('please-enter-password.', 'Please Enter Password')}}.</p>
                                        </div>

                                        <div class="remember-checkbox-wrapper">
                                            <div class="remember-checkbox-sec">
                                                <input type="checkbox" v-model="form.isChecked" id="remember-text">
                                                    <label for="remember-text">{{ translatedLangData('remember-me', 'Remember Me') }}</label>
                                            </div>
                                            <small>{{ translatedLangData('site-protected-title', 'This Site Is Protected By ReCAPTCHA And The Google') }} 
                                                <a href="javascript:void(0);">{{ translatedLangData('privacy-policy', 'Privacy Policy') }}</a> 
                                                {{ translatedLangData('and', 'And') }} 
                                                <a href="javascript:void(0);">{{ translatedLangData('terms-of-serv', 'Terms Of Service Apply') }}</a>.
                                            </small>
                                        </div>

                                        <div class="for-pass" v-if="siteSettings && siteSettings?.business_type == 2">
                                            <a data-bs-toggle="modal" href="#staticBackdrop-two" @click="refreshData()">
                                                {{ translatedLangData('forgot-password','Forgot Password?')}}
                                            </a>
                                        </div>

                                        <div class="login-btn-wrapper">
                                            <button type="button" @click="signInMethodCall()" class="btn btn-register btn-login-blk loader-btn"
                                                :class="{ 'btn-loading': loading }">
                                                {{ translatedLangData('login','Login')}}
                                                <span><b></b><b></b><b></b></span>
                                            </button>
                                            <button type="button" class="btn btn-register btn-login-blk loader-btn" @click="demoUserLogin()"
                                                :class="{ 'btn-loading': demoLoading }">
                                                {{ translatedLangData('demo-login','Log In With Demo ID ')}}
                                                <span><b></b><b></b><b></b></span>
                                            </button>
                                        </div>
                                        
                                        <!-- <template v-if="siteSettings && siteSettings?.business_type == 2">
                                            <h3 class="whats-with">{{ translatedLangData('or-login-with','Or Login With')}}</h3>
                                            <ul class="social-icon-wrapper">
                                                <li>
                                                    <a href="Javascript:void(0)">
                                                        <img src="@/assets/images/google.png" class="google">
                                                    </a>
                                                </li>
                                            </ul>
                                        </template> -->

                                        <h3 class="whats-with">{{ translatedLangData('get-your-ready-made-id-from-whatsapp', 'Get Your Ready-Made ID From WhatsApp') }}</h3>
                                        <div v-if="siteSettings?.social_link?.whatsappno1" class="button-whatsapp">
                                            <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" class="btn-whatsapp" target="_blank">
                                                <img src="@/assets/images/whatsapp.webp" alt="">
                                                {{ translatedLangData('whatsapp-now ','Whatsapp Now ')}}
                                            </a>
                                        </div>
                                        
                                        <template v-if="siteSettings && siteSettings?.business_type == 2">
                                            <p class="whats-with-acc">{{ translatedLangData("dont-have-an-account-sign-up","Don't Have An Account?")}} &nbsp;
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#register-btn" @click="refreshData()">
                                                    {{ translatedLangData('register','Register')}}
                                                </a>
                                            </p>
                                        </template>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import { Modal } from "bootstrap";

export default {
    name: "Login",
    inject:['translatedLangData'],
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    data() {
        return {
            form: {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: '',
                isChecked: false
            },
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            loading: false,
            modal: null,
            captchaData: null,
            passwordFieldType: 'password',
            demoLoading: false,
            loggedUserCheck:null,
            inputvalue : [
                {
                    value: 1000,
                },
                {
                    value: 2000,
                },
                {
                    value: 3000,
                },
                {
                    value: 4000,
                }
            ]
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        }
    },
    mounted(){
        this.loggedUserCheck=localStorage.getItem('LoggedUser');
        if(localStorage.getItem('login_type') && this.siteSettings?.business_type == 2) {
            this.form.type = localStorage.getItem('login_type');
        }
        if(this.$route.name == 'login' && !this.checkIsLogin()) {
            let loginModal = new Modal('#staticBackdrop-one');
            loginModal.show();
        }
        else if(this.$route.name == 'login' && this.checkIsLogin()) {
            this.$router.push('/');
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: '',
                isChecked: false
            },
                this.showValidationError = false,
                this.passwordFieldType = 'password'
        },
        demoUserLogin() {
            this.form.type = 1;
            this.form.userId = "demouser";
            this.form.password = "demopass";
            this.loginServiceCall(apiName.DEMO_LOGIN_API, 'demo');
        },
        signInMethodCall() {
            if (!this.loading) {
                let id = this.getId();
                if (id == "" || this.form.password == "" ) {
                    this.showValidationError = true;
                }
                else {
                    this.loginServiceCall(apiName.LOGIN_API, 'nondemo');
                }
            }
        },
        async loginServiceCall(url, type) {
            let id = this.getId();
            if (type == 'demo') {
                this.demoLoading = true;
            } else {
                this.loading = true;
            }

            let data = {
                "userid": id.toString(),
                "type": this.siteSettings?.business_type == 1 ? 2 : this.form.type,
                "password": this.form.password,
                "cncode": this.form.code,
            };
            api.post(url, data).then(response => {
                if (type == 'demo') {
                    this.demoLoading = false;
                } else {
                    this.loading = false;
                }
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            let userData = response.data.data;
                            if(this.loggedUserCheck!=userData.userid){
                            this.$store.dispatch('setClickBetValue',this.inputvalue);
                            }
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                            this.$store.dispatch('setToken', `Bearer ${userData.token}`);
                            window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                            //for add on all APIs
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            localStorage.setItem('login_type', data.type)
                            setHeaders()
                            this.setUserDataToStore(userData);
                            localStorage.setItem("LoggedUser",userData.userid)
                            this.refreshData();
                            this.$router.go();
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                if (type == 'demo') {
                    this.demoLoading = false;
                } else {
                    this.loading = false;
                }
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        clickOnType(type) {
            this.form.type = type;
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
            this.form.password = "";
            this.showValidationError = false
        },
        getId() {
            if (this.siteSettings?.business_type == 2) {
                return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
            } else {
                return this.form.userId;
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        setUserDataToStore(userData) {
            let asciiCodes = null;
            for (let i = 0; i < userData.userid.length; i++) {
                asciiCodes += userData.userid.charCodeAt(i);
            }
            let user =
            {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                rid: userData.rid,
                userid: userData.userid,
                exposure: userData.expl,
                available_credits: userData.available_credits,
                id: userData.id,
                exp: userData.exp,
                activeWallet: userData.actw,
                cpi: userData.cpi,
                asciiCodes: asciiCodes,
                notificationSubscribed:null,
                notificationUnsubscribed:null,
            }
            this.$store.dispatch('setUser', user);
            this.$store.dispatch('setChips', userData.chips);
            this.$store.dispatch('setEventManage', userData.lock_settings);
        },
        handleInput(event) {
            this.form.mobileNo = event.target.value.replace(/\D/g, '');
        },
        handleInputUserID(event) {
            const containsSpaces = /\s/.test(this.form.userId);
            if (containsSpaces) {
                this.form.userId = this.form.userId.replace(/\s/g, '');
            }
        },
        togglePasswordVisibility(fieldName) {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        },
        filterSpaces(event) {
            const sanitizedPassword = event.target.value.replace(/[^\w !@#$%^&*()+\-*/]/g, '');
            this.form.password = sanitizedPassword;
            const containsSpaces = /\s/.test(this.form.password);
            
            if (containsSpaces) {
                this.form.password = this.form.password.replace(/\s/g, '');
            }
        }
    },
};
</script>

<style scoped>
div.mm-dropdown ul li {
    display: flex !important;
    padding-left: 25px;
}
select#autoSizingSelect {
    font-size: 14px;
}
.pl-0 {
    padding-left: 0px !important;
}
</style>